<div class="container">
    <div class="full-bg-img">
        <div class="mask rgba-black-light flex-center">
            <div class="container text-center white-text">
                <div class="white-text text-center wow fadeInUp">
                    
                    <div class="container">
                        <div class="row" style="margin-bottom:20px;">
                            <div class="col-md-3 border-right">
                                <h2>Desktop</h2>
                                <h5>
                                    ● Visual Basic 6<br>
                                    ● VB / C# .NET
                                </h5>
                            </div>
                            <div class="col-md-3 border-right">
                                <h2>Web Front-End</h2>
                                <h5>
                                    ● HTML & Javascript<br>
                                    ● Jquery<br>
                                    ● Bootstrap<br>
                                    ● AngularJS<br>
                                    ● Angular<br>
                                    ● MDB Bootstrap<br>
                                    ● *Others<br>
                                </h5>
                            </div>
                            <div class="col-md-3 border-right">
                                <h2>Mobile Front-End</h2>
                                <h5>
                                    ● Nativescript<br>
                                    ● Android / iOS<br>
                                </h5>
                            </div>
                            <div class="col-md-3">
                                <h4>Scrum + DevOps</h4>
                                <h5>
                                    ● JIRA<br>
                                    ● Git<br>
                                    ● Trello<br>
                                    ● Circle CI<br>
                                    ● Jenkins<br>
                                    ● Sentry<br>
                                </h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 border-right">
                                <h2>Database</h2>
                                <h5>
                                    ● Microsoft SQL Server<br>
                                    ● MySQL<br>
                                    ● PostgreSQL<br>
                                    ● NoSQL (Firebase)<br>
                                </h5>
                            </div>
                            <div class="col-md-3 border-right">
                                <h2>Back-End</h2>
                                <h5>
                                    ● PHP<br>
                                    ● Node.js<br>
                                    ● Express JS<br>
                                    ● Loopback 3<br>
                                </h5>
                            </div>
                            <div class="col-md-3 border-right">
                                <h2>Platform Cloud</h2>
                                <h5>
                                    ● AWS<br>
                                    ● Google Cloud<br>
                                    ● Digital Ocean<br>
                                </h5>
                            </div>
                            <div class="col-md-3">
                                <h4>Others 3rd party Integration</h4>
                                <h5>
                                    ● Sendgrid (Email)<br>
                                    ● Waboxapp (WA)<br>
                                    ● SMS Messaging<br>
                                    ● Xero Accounting<br>
                                    ● Quickbooks Online<br>
                                    ● MYOB<br>
                                    ● Accurate Online<br>
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

