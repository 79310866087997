import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teknologi',
  templateUrl: './teknologi.component.html',
  styleUrls: ['./teknologi.component.scss']
})
export class TeknologiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
