<footer class="page-footer font-small blue pt-4">
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col-md-6 mt-md-0 mt-3">
        <h5 class="text-uppercase">Hubungi Kami</h5>
        <p>
            Andra Basuki S.Si, S.Kom, M.M. / 
            <a style="color:lightblue;" href="https://wa.me/628170900111/?text=Hi, saya ingin bertanya mengenai Garasi Pintar"><i class="fab fa-whatsapp"></i> 0817-0900-111</a> / 
            <a style="color:lightblue;" href="mailto:andrabasuki@gmail.com?subject=Garasi Pintar&body=Hi, saya ingin bertanya mengenai Garasi Pintar"><i class="far fa-envelope"></i> andrabasuki@gmail.com</a>
            <br>
            Hans Hanggodo S.Sos, M.M. / 
            <a style="color:lightblue;" href="https://wa.me/6281282998111/?text=Hi, saya ingin bertanya mengenai Garasi Pintar"><i class="fab fa-whatsapp"></i> 0812-8299-8111</a> / 
            <a style="color:lightblue;" href="mailto:hanz_magic@yahoo.com?subject=Garasi Pintar&body=Hi, saya ingin bertanya mengenai Garasi Pintar"><i class="far fa-envelope"></i> hanz_magic@yahoo.com</a>
        </p>
      </div>
      <hr class="clearfix w-100 d-md-none pb-3">
      <div class="col-md-3 mb-md-0 mb-3">
          <ul class="list-unstyled">
            <h5 class="text-uppercase">Sitemap</h5>
            <li>
              <a href="/solusi">- Solusi</a>
            </li>
            <li>
              <a href="/teknologi">- Teknologi</a>
            </li>
            <!--<li>
              <a href="/studikasus">- Studi Kasus</a>
            </li>-->
            <li>
              <a href="/pelanggan">- Pelanggan</a>
            </li>
            <li>
              <a href="/tentangkami">- Tentang Kami</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-md-0 mb-3">
          <h5 class="text-uppercase">Links</h5>
          <ul class="list-unstyled">
            <li>
                <a target="_new" href="http://abas.web.id/">abas.web.id</a>
            </li>
            <li>
                <a target="_new" href="https://home.garasipintar.com/">GP</a>
            </li>
          </ul>
        </div>
    </div>
  </div>
  <div class="footer-copyright text-center py-3">© 2018 - {{ tahun }} Copyright:
    <a href="https://garasipintar.com"> garasipintar.com</a>
  </div>
</footer>
