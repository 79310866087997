import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NavItemsComponent } from './header/nav-items/nav-items.component';
import { NavSearchComponent } from './header/nav-search/nav-search.component';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { SolusiComponent } from './solusi/solusi.component';
import { TeknologiComponent } from './teknologi/teknologi.component';
import { StudikasusComponent } from './studikasus/studikasus.component';
import { PelangganComponent } from './pelanggan/pelanggan.component';
import { TentangkamiComponent } from './tentangkami/tentangkami.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavItemsComponent,
    NavSearchComponent,
    MainComponent,
    FooterComponent,
    SolusiComponent,
    TeknologiComponent,
    StudikasusComponent,
    PelangganComponent,
    TentangkamiComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
