import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solusi',
  templateUrl: './solusi.component.html',
  styleUrls: ['./solusi.component.scss']
})
export class SolusiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
