<div class="view" style="height:500px;">
    <div class="full-bg-img">
        <div class="mask rgba-black-light flex-center">
            <div class="container text-center white-text">
                <div class="white-text text-center wow fadeInUp">
                    <h2>Custom Software Business Development</h2>
                    <h5>GP melayani pembuatan aplikasi baik web, <i>desktop</i> dan <i>mobile</i> untuk menunjang operasional bisnis anda sehari-hari.</h5>                    
                </div>
            </div>
        </div>
    </div>
</div>

