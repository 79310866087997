<header class="h-100">
    <mdb-navbar SideClass="navbar navbar-1 navbar-dark blue navbar-expand-lg" [containerInside]="false">
        <mdb-navbar-brand>
            <a href="https://garasipintar.com" class="logo navbar-brand">
              <img src="/assets/gp_logo_big.png" width="50px;" class="img-fluid flex-center">
            </a>
        </mdb-navbar-brand>
        <links>
            <ul class="navbar-nav mr-auto">
                <li class="nav-item waves-light" mdbWavesEffect>
                    <a href="/solusi" class="nav-link">Solusi</a>
                </li>
                <li class="nav-item waves-light" mdbWavesEffect>
                    <a href="/teknologi" class="nav-link">Teknologi</a>
                </li>
                <!--<li class="nav-item waves-light" mdbWavesEffect>
                    <a href="/studikasus" class="nav-link">Studi Kasus</a>
                </li>-->
                <li class="nav-item waves-light" mdbWavesEffect>
                    <a href="/pelanggan" class="nav-link">Pelanggan</a>
                </li>
                <li class="nav-item waves-light" mdbWavesEffect>
                    <a href="/tentangkami" class="nav-link">Tentang Kami</a>
                </li>
            </ul>
        </links>
    </mdb-navbar> 
</header>
