import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SolusiComponent } from './solusi/solusi.component';
import { TeknologiComponent } from './teknologi/teknologi.component';
import { StudikasusComponent } from './studikasus/studikasus.component';
import { PelangganComponent } from './pelanggan/pelanggan.component';
import { TentangkamiComponent } from './tentangkami/tentangkami.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: MainComponent },
  { path: 'solusi', component: SolusiComponent },
  { path: 'teknologi', component: TeknologiComponent },
  { path: 'studikasus', component: StudikasusComponent },
  { path: 'pelanggan', component: PelangganComponent },
  { path: 'tentangkami', component: TentangkamiComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }