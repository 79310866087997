<div class="view" style="height:500px;">
    <div class="full-bg-img">
        <div class="mask rgba-black-light flex-center">
            <div class="container text-center white-text">
                <p>*Bagian ini masih dalam tahap pengembangan*</p>
            </div>
        </div>
    </div>
</div>

