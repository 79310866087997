<div class="container">
    <div class="full-bg-img" style="height:500px;">
        <div class="mask rgba-black-light flex-center">
            <div class="container text-center white-text">
                <div class="white-text text-center wow fadeInUp">
                    
                    <div class="container" style="overflow-y:scroll; height:350px;">
                        <div class="row">
                            <div class="col-md-12">
                                <h4>
                                    ● Custom Web App Development<br>
                                    ● Custom Desktop App Development<br>
                                    ● Custom Mobile App Development<br>
                                    ● Custom POS Android and Windows Application<br>
                                    ● Custom Rental Management System<br>
                                    ● Custom Invoice Collection System<br>
                                    ● Custom Inventory Management System<br>
                                    ● Custom Attendance Solution<br>
                                    ● Custom Accounting Solution<br>
                                    ● Custom Kost Management System<br>
                                    ● Integrasi ke aplikasi lain seperti software akuntansi
                                </h4>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

