<div class="container">
    <div class="full-bg-img">
        <div class="mask rgba-black-light flex-center">
            <div class="container">
                
                <div class="row">
                    <div class="card-deck" style="margin:50px 50px 0px 50px;">
                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="width:120px; height:120px;">
                                <mdb-card-img src="/assets/fluvvy-freezer-small.png" alt="fluVVy"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4><a target="_new" href="https://www.fluvvyfreezer.com/">fluVVy</a></h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                    Rental Management System for fluVVy, a company that rent freezer for "ASI" and children toys.<br>
                                    Serve delivery over Serpong (BSD City, Gading Serpong, Alam Sutera, Graha Raya, dll.), Pamulang, Tangerang, Bintaro, Jakarta Selatan, dan Jakarta Barat area.
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>

                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="height:90px; margin-top:30px;">
                                <mdb-card-img src="/assets/bikeandco.png" alt="bikeandco"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4><a target="_new" href="https://bikeandco.garasipintar.com">Bike & Co</a></h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                POS Aplication (Android and Web) for Bike & Co. to support their operation to manage over 20 counters all over Indonesia.<br>
                                Including Stock Management System.
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>

                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="height:90px; width:120px; margin-top:25px;">
                                <mdb-card-img src="/assets/tdk.jpeg" alt="tdk"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4>TDK</h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                Desktop Application for TDK with Visual Basic and Sql Server.<br>
                                Point of Sales application for managing sales, payments, receivables and item's stock for the store.
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </div>

                <div class="row">
                    <div class="card-deck" style="margin:50px;">
                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="height:120px; width:100px; margin-top:25px;">
                                <mdb-card-img src="/assets/logo_kobra.png" alt="Kobra Express"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4><a target="_new" href="https://sparepart.garasipintar.com/">Panca Kobra Sakti</a></h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                Build Collection, Cash and Sparepart Management System for PT Panca Kobra Sakti,<br>
                                a forwarding company with main experience for handling cargo in Java Island (Indonesia), which brand is "Kobra Express".
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>

                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="height:90px; margin-top:55px;">
                                <mdb-card-img src="https://masteryourenglish.com/images/ilp_logo_biru_bg_putih.png" alt="ILP"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4><a target="_new" href="https://masteryourenglish.com/">ILP SIPS</a></h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                Combined Desktop and Web Application for processing Student Information, Student Score and Payment
                                for 5 branch of ILP : Bintaro, Bogor, BSD, Cikini, Cinere.
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>

                        <mdb-card>
                            <div class="view overlay waves-light mx-auto" mdbWavesEffect style="height:120px; margin-top:20px;">
                                <mdb-card-img src="/assets/ji.jpg" alt="JI"></mdb-card-img>
                                <a>
                                    <div class="mask rgba-white-slight"></div>
                                </a>
                            </div>
                            <mdb-card-body>
                            <mdb-card-title>
                                <h4><a target="_new" href="http://www.jefferys.co.id/">Jefferys Indonesia</a></h4>
                            </mdb-card-title>    
                            <mdb-card-text> 
                                Desktop Application for PT Jefferys Indonesia, a bicycle company and principal distributor for some known bicycle brands in Indonesia.
                            </mdb-card-text>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </div>

                <div class="row">
                        <div class="card-deck" style="margin:50px;">
                            
                            <mdb-card>
                                <div class="view overlay waves-light mx-auto" mdbWavesEffect style="margin:25px 0px 20px 0px;">
                                    <mdb-card-img src="/assets/eztna.png" alt="EZ-TNA"></mdb-card-img>
                                    <a>
                                        <div class="mask rgba-white-slight"></div>
                                    </a>
                                </div>
                                <mdb-card-body>
                                <mdb-card-title>
                                    <h4><a target="_new" href="http://www.mpsolusindo.com/">PT. Mitra Piranti Solusindo</a></h4>
                                </mdb-card-title>    
                                <mdb-card-text> 
                                    Making a package program with various solution for time attendance problem.<br>
                                    This application is sold as one package with fingerprint device an designed to work with <a target="_new" href="http://www.nitgen.com/">Nitgen</a> brands only.
                                </mdb-card-text>
                                </mdb-card-body>
                            </mdb-card>

                            <mdb-card>
                                <div class="view overlay waves-light mx-auto" mdbWavesEffect style="width:150px; margin:30px 0px 25px 0px;">
                                    <mdb-card-img src="/assets/kostkita.png" alt="KostKita"></mdb-card-img>
                                    <a>
                                        <div class="mask rgba-white-slight"></div>
                                    </a>
                                </div>
                                <mdb-card-body>
                                <mdb-card-title>
                                    <h4>KostKita</h4>
                                </mdb-card-title>    
                                <mdb-card-text> 
                                    Build an android application for "Kost" or Boarding House to manage his Collection System, the client have over 30 rooms.
                                </mdb-card-text>
                                </mdb-card-body>
                            </mdb-card>
                            
                        </div>
                </div>

            </div>
        </div>
    </div>
</div>
