import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

const settings = {timestampsInSnapshots: true};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'GP';
}
