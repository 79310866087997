<div class="container">
    <div class="full-bg-img">
        <div class="mask rgba-black-light flex-center">
            <div class="container text-center">
                <div class="white-text text-center wow fadeInUp" style="margin:50px;">
                    <h2>Tentang Kami</h2>
                    <h5>Garasi Pintar didirikan oleh Andra & Hans pada November 2018. Tujuan didirikan'nya GP adalah untuk membantu pengusaha khusus'nya kecil dan menengah agar dapat membuat program dan menerapkan'nya untuk membantu operasional bisnis mereka sehari-hari.</h5>
                </div>

                <div class="card-deck" style="margin-bottom:50px;">
                
                            <mdb-card>

                                <!--Avatar-->
                                <div class="avatar mx-auto" style="margin-top:20px;">
                                    <img src="/assets/andra.jpg" class="rounded-circle">
                                </div>

                                <!--Card content-->
                                <mdb-card-body>
                                
                                    <!--Title-->
                                    <mdb-card-title>
                                        <h4>Andra Basuki S.Si., S.Kom., M.M.</h4>
                                    </mdb-card-title>
                                
                                    <!--Text-->
                                    <mdb-card-text> 
                                        ● S1 Bina Nusantara Jurusan Teknik Informasi dan Matematika.<br>
                                        ● S2 Bina Nusantara Business School Jurusan Business Management.<br>
                                        ● 14 tahun pengalaman di bidang programming.<br>
                                        ● 4 tahun di bidang startup.<br>
                                        ● Last position : Tech Lead at Foodrazor Pte Ltd.<br>
                                    </mdb-card-text>
                                
                                </mdb-card-body>
                            </mdb-card>
                        
                            <mdb-card>
                                <!--Avatar-->
                                <div class="avatar mx-auto" style="margin-top:20px;">
                                    <img src="/assets/hans.jpg" class="rounded-circle">
                                </div>

                                <!--Card content-->
                                <mdb-card-body>
                                
                                    <!--Title-->
                                    <mdb-card-title>
                                        <h4>Hans Hanggodo S.Sos., M.M</h4>
                                    </mdb-card-title>
                                
                                    <!--Text-->
                                    <mdb-card-text> 
                                        ● S1 Atma Jaya Fakultas Ilmu Administrasi Niaga dengan predikat cum-laude.<br>
                                        ● S2 Bina Nusantara Business School Jurusan Business Management.<br>
                                        ● 1.5 tahun pengalaman kerja di PT. Danpac Futures.<br>
                                        ● 7 tahun pengalaman di bisnis onderdil.<br>
                                        ● 2 tahun pengalaman di bidang IT project management.<br>
                                    </mdb-card-text>
                                
                                </mdb-card-body>
                            </mdb-card>
                        
                    
                </div>

            </div>
        </div>
    </div>
</div>

